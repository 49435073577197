import { ReactComponent as Logo } from "../assets/design/logo-alt.svg";
import banner from "../assets/images/leafBannerSmaller.jpg";
import defaultImage from "../assets/images/sign-square.png";
import Guarantee from "../components/guarantee";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Service from "../components/service";
import StyledButton from "../components/styledButton";
import "../css/markdown.css";
import dayjs from "dayjs";
import { Link, graphql, withPrefix } from "gatsby";
import React, { useEffect } from "react";

const ObitPage = ({ data, location }) => {
  const obituary = data.obituary;

  const {
    name,
    slug,
    city,
    content: {
      data: { content },
    },
    state,
    deathdate,
    age,
    image,
  } = obituary;
  console.log(content, image);
  const imageURL = image?.localFile?.publicURL
    ? withPrefix(image.localFile.publicURL)
    : defaultImage;

  const services = data.services.edges;

  useEffect(() => {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
      fjs.parentNode.insertBefore(js, fjs);
    })(
      // () => {
      //   if (typeof document !== `undefined`) {
      //     return {
      //       document,
      //       // Do something with the document
      //       // Call your modules and libraries
      //     };
      //   } else {
      //     return null;
      //   }
      // },
      document,
      "script",
      "facebook-jssdk"
    );
  }, []);

  return (
    <>
      <SEO
        title={`Obituary for ${name} | City of Oaks Cremation`}
        description={`Obituary for ${name} | City of Oaks Funeral Home and Cremation helps families honor and celebrate the lives of their loved ones every day.`}
        pathname={location.pathname}
      />
      <Layout
        headerClass="relative bg-white"
        bodyClass="px-0 md:px-0 lg:px-0"
        hasHero={false}
        heroImage={banner}
      >
        <div className="flex flex-col mt-6">
          <div className="w-24 h-24 self-center">
            <Logo />
          </div>
          <div className="text-center">
            <h2 className="text-xl md:text-2xl uppercase text-gray-700">
              City of Oaks Funeral Home & Cremation
            </h2>
          </div>
        </div>

        <div className="container mx-auto px-6 md:px-10 lg:px-24 pt-8 max-w-prose">
          <div className="flex flex-col">
            <div
              className={`${
                content ? "mx" : "mx-auto"
              } markdown-body py-6 text-lg`}
            >
              <h1>
                <span className="text-gray-700 text-sm">Obituary</span>
                <br />
                <span className="text-3xl md:text-4xl text-green-700 font-serif font-light">
                  {name}
                </span>
              </h1>
              <h3 className="text-gray-700 text-sm">
                {city}, {state}
              </h3>
              <h3 className="text-gray-700 text-sm">
                Age {age}. <br />
                Died {dayjs(deathdate).format("MM/DD/YYYY")}.
              </h3>

              <div className={"float-none md:float-left md:mr-4"}>
                <div>
                  <img
                    className="mt-4 mx-auto"
                    // height='200px'
                    width="200"
                    alt={name}
                    src={imageURL}
                  />
                </div>
              </div>
              <div
                className="col-span-2 mt-2"
                style={{ textIndent: "1rem" }}
                dangerouslySetInnerHTML={{
                  __html: content.replace(/\n/g, "<br />"),
                }}
              ></div>
              {/* </container> */}
            </div>
            <p className="text-lg pb-8">
              For additional information or for service details, please reach
              out to the family directly.
            </p>
            <br />
            <p className="text-lg pb-8">
              You can share this obituary using the following address, or click
              the button below to share on Facebook.
            </p>
            <code class="text-xs md:text-sm overflow-x-auto self-center mx-10">
              {location.href}
            </code>

            <div className="self-center mb-10 mt-6">
              {/* <StyledButton
              onClick={() => {
                FB.ui(
                  {
                    method: 'share',
                    href: location.href,
                  },
                  function (response) {}
                );
              }}>
              Share
            </StyledButton> */}
              <div
                className="fb-share-button"
                data-href={location.href}
                data-layout="button"
                data-size="large"
              >
                <a
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.cityofoakscremation.com%2Fobituaries%2F${slug}&amp;src=sdkpreparse`}
                  className="fb-xfbml-parse-ignore"
                >
                  Share
                </a>
              </div>{" "}
            </div>

            <div className="self-center mb-10 mt-10">
              <Link to="/obituaries/">
                <StyledButton>All Obituaries</StyledButton>
              </Link>
            </div>
          </div>
          {/* </div> */}
          <div className="text-lg py-6">
            <p className="pb-4">
              City of Oaks Cremation and Funeral Home is a full-service funeral home in Raleigh, serving all of North Carolina and beyond. We provide an alternative to costly funeral homes and every day we help families in Raleigh, Durham, Chapel Hill and all over North Carolina make simple, affordable funeral arrangements.
            </p>
            <p>
              Simply having to reach out to a funeral home can feel
              overwhelming. Our goal is to simplify the entire funeral home
              experience and make the process as easy as possible. We hope to
              eliminate any unnecessary surprises by publishing our total cost
              and the services we provide directly on our website. All of our
              obituaries are provided free of charge as a way to help families
              celebrate and commemorate the lives of their loved ones.
            </p>
          </div>
        </div>
        <Guarantee />

        <div className="flex flex-wrap justify-center items-stretch -mx-2">
          <p className="mb-2 text-4xl text-gray-800 self-center">
            Our Services
          </p>
          <div className="flex flex-wrap justify-center items-stretch -mx-2">
            {services.map(({ node }) => (
              <Service
                title={node.frontmatter.title}
                slug={node.frontmatter.slug}
                url={node.frontmatter.path}
                key={node.frontmatter.path}
                icon={node.frontmatter.icon}
                price={node.frontmatter.price}
                blurb={node.frontmatter.blurb}
              ></Service>
            ))}
          </div>

          <div className="self-center my-4">
            <Link to="/services/">
              <StyledButton>View all services</StyledButton>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    obituary: strapiArticle(id: { eq: $id }) {
      id
      slug
      name
      content {
        data {
          content
        }
      }
      city
      state
      deathdate
      age
      image {
        localFile {
          publicURL
        }
      }
    }
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            path
            icon
            price
            blurb
          }
          excerpt
        }
      }
    }
  }
`;

// export const query = graphql`
//   query($id: String) {
//     obituary: markdownRemark(id: { eq: $id }) {
//       frontmatter {
//         path
//         name
//         first
//         middle
//         last
//         suffix
//         city
//         county
//         state
//         deathdate
//         age
//         date
//         draft
//         image
//       }
//       html
//     }
//     services: allMarkdownRemark(
//       filter: { fileAbsolutePath: { regex: "/content/services/" } }
//     ) {
//       edges {
//         node {
//           frontmatter {
//             title
//             slug
//             path
//             icon
//             price
//             blurb
//           }
//           excerpt
//         }
//       }
//     }
//   }
// `;
export default ObitPage;
